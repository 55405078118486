<template>
    <div class="settings-form">
        <h1 class="typography--h1-bold">{{ $t(form.label) }}</h1>
        <component :is="form.type" :data="form.data" class="settings-form-inner" />
    </div>
</template>

<script>
    import { sections } from './sectioncomponents'

    export default {
        name: 'SettingsForm',
        data(){
            return {
                form: {
                    label: '',
                    type: '',
                    data: {}
                }
            }
        },
        mounted() {
            const _section = this.$route.params.section
            const current = sections.find(section => section.type === this.upperFirst(_section))
            const data = this.$store.state.settings[_section].data
            const id = this.$route.params.id
            this.form.label = current.title
            this.form.type = current.component

            if(id)
                this.form.data = data.find((item) => parseInt(item.id) === parseInt(id))
        },
        methods: {
            upperFirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '@/assets/vars.scss';

    .settings-form{
        padding: 32px 40px;
        background-color: $white;

        display: flex;
        flex-flow: column;
        justify-content: center;

        max-width: 660px;
        margin: 0 auto;

        h1{
            border: solid #555555;
            border-width: 0 0 1px;
            margin-bottom: 2.5rem;
        }

        &-inner {
            width: 100%;

            ::v-deep{
                .buttons{
                    display: flex;
                    margin: 2.5rem 0;
                    justify-content: center;
                }
            }
        }
    }
</style>
